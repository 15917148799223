window.pursuit = window.pursuit || {};

(function (){
    pursuit.ajaxError = function () {
        alert("Uh oh... Something went wrong. Please email thepursuitofsquash@gmail.com if this happens again!");
    };

    Number.isInteger = Number.isInteger || function(value) {
        return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    };

    Number.isPositiveInteger = function (value) {
        if(Number.isInteger(value)) {
            return parseInt(value) > -1;
        }
        return false;
    };

    Number.round = function(value, precision) {
        var multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) /multiplier;
	};
	
	pursuit.timeConversion = function(millis) {
        var dur = {};
        var units = [
            {label:"ms",    mod:1000},
            {label:"sec",   mod:60},
            {label:"min",   mod:60},
            {label:"hours",     mod:24}
        ];
        // calculate the individual unit values...
        units.forEach(function(u){
            millis = (millis - (dur[u.label] = (millis % u.mod))) / u.mod;
        });
        // convert object to a string representation...
        var nonZero = function(u){ return dur[u.label]; };
        dur.toString = function(){
            return units
                .reverse()
                .filter(nonZero)
                .map(function(u){
                    return dur[u.label] + " " + u.label;
                })
                .join(' ');
        };
        return dur;
    };
    pursuit.getQueryParam = function (name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
})();

// ToDo - Put this somewhere better - Recall the IE 11 fun of cached ajax calls - PR to UserFrosting in uf-alerts.js?
$.ajaxSetup({
    // Disable caching of AJAX responses
    cache: false
});

$(function () {
    var originalAjax = $.ajax;

    $.ajax = function(options) {

		var ajaxOptions = $.extend(true, {}, options);
		var defaultHandler = function() {
			// Do Nothing D:
		};
		var errorCallback;

		if (typeof(ajaxOptions.error) === 'object') {
			var handlers = ajaxOptions.error;
			var handler = function(jqXHR, textStatus, errorThrown) {
				if (typeof(handlers[jqXHR.status]) === 'function') {
					handlers[jqXHR.status](jqXHR, textStatus, errorThrown);
				} else {
					defaultHandler(jqXHR, textStatus, errorThrown);
				}
			};
			errorCallback = handler;
		} else if (typeof(ajaxOptions.error) !== 'function') {
			errorCallback = defaultHandler;
		} else {
			errorCallback = ajaxOptions.error;
		}

		var globalErrorHandler = function (jqXHR, textStatus, errorThrown) {
			if(jqXHR.status) {
				errorCallback(jqXHR, textStatus, errorThrown);
			}
        };
        
		ajaxOptions.error = globalErrorHandler;

        return originalAjax(ajaxOptions);
    };
    

    const referrerId = pursuit.getQueryParam('_posref');
    if (referrerId) {
        $.cookie('pos_referral_user_id', referrerId, { expires: 7, path: '/' });
    }
});